import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";
import type { ColorProfileTheme } from "aviary-tokens/dist/types";

import { dimensions, timing } from "@styles";

export const base = (theme: Theme) => css`
  border-radius: ${dimensions.pillBorderRadius};
  text-align: center;
  font-weight: ${typography.weightSemiBold};
  font-size: ${theme.aviaryTypography.body.fontSize};
  min-width: 2rem;
  display: flex;
  align-items: center;
  padding: 0.25rem 1rem;
`;

export const lightPill = (theme: Theme) => css`
  color: ${theme.light.textBase};
  background-color: ${theme.light.backgroundBase};
  border-color: transparent;

  path.fa-spinner-third {
    fill: ${theme.light.textBase};
  }
`;

export const accentColor = {
  sand: (theme: Theme) => css`
    background-color: ${theme.accent.sand.backgroundMuted0};
    color: ${theme.accent.sand.textEmphasis};
  `,
  forest: (theme: Theme) => css`
    background-color: ${theme.accent.forest.backgroundMuted0};
    color: ${theme.accent.forest.textEmphasis};
  `,
};

export const size = {
  xsmall: css`
    font-size: 10px;
    padding: 0rem 0.25rem;
  `,
  small: (theme: Theme) => css`
    font-size: ${theme.aviaryTypography.footnote.fontSize};
    padding: 0.25rem 0.5rem;
  `,
  medium: (theme: Theme) => css`
    font-size: ${theme.aviaryTypography.body.fontSize};
    padding: 0.25rem 1rem;
  `,
};

export const icon = {
  start: css`
    margin-left: 0;
    margin-right: 0.5rem;
  `,
  end: css`
    margin-left: 0.5rem;
    margin-right: 0;
  `,
};

export const themedStyles = (theme: ColorProfileTheme) => css`
  color: ${theme.textHover};
  background-color: ${theme.backgroundMuted};
  border-color: transparent;

  path.fa-spinner-third {
    fill: ${theme.textBase};
  }
`;

export const loader = css`
  margin-right: 0.5rem;

  svg {
    animation-duration: ${timing.slow};
  }
`;

export const disabled = (theme: Theme) => css`
  color: ${theme.disabled.text};
  background-color: ${theme.disabled.background};

  &&&:hover {
    cursor: not-allowed;
  }
`;
