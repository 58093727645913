import { css, type Theme } from "@emotion/react";

export const wrapper = css`
  display: inline-block;
  position: relative;
`;

export const tooltipOverrides = (theme: Theme) => css`
  .MuiTooltip-tooltip strong {
    color: ${theme.inverted.textEmphasis};
  }
  .MuiTooltip-tooltip a,
  .MuiTooltip-tooltip button {
    color: ${theme.inverted.textEmphasis};

    &:hover,
    &:active {
      color: ${theme.inverted.textEmphasis};
    }
  }
`;

export const textDirection = {
  left: css`
    text-align: left;
  `,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: right;
  `,
};
