import { css, type Theme } from "@emotion/react";

import { animations } from "@styles";

export const checkbox = {
  base: (theme: Theme) => css`
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
    gap: 0.5rem;
    color: ${theme.text.body};
  `,
  isFullWidth: css`
    width: 100%;
  `,
  disabled: css`
    cursor: not-allowed;
    user-select: none;
  `,
  top: css`
    align-items: flex-start;
  `,
};

export const checkboxInput = (theme: Theme) => css`
  position: absolute;
  left: -9999px;

  & + div {
    ${animations.transition("opacity")};
    border-color: ${theme.primary.borderHover};
    background-color: ${theme.system.backgroundBase};
  }

  & + div > svg {
    color: ${theme.primary.textBase};
  }

  &:checked + div,
  &:indeterminate + div {
    > svg {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  &:hover + div {
    border-color: ${theme.primary.borderActive};
    background-color: ${theme.primary.backgroundMutedHover};
  }
  &:focus-visible + div,
  &:active + div {
    outline: 1px solid ${theme.primary.borderActive};
    outline-offset: 1px;
    background-color: ${theme.primary.backgroundMuted};
  }
  &:checked + div,
  &:indeterminate + div {
    background-color: ${theme.primary.backgroundBase};
    border-color: ${theme.primary.backgroundBase};
  }
  // Label text
  &:active + div + div,
  &:focus-visible + div + div {
    color: ${theme.text.body};
  }
  &:hover + div + div {
    color: ${theme.text.emphasis};
  }
  &[disabled] {
    &:hover + div + div {
      color: ${theme.disabled.text};
    }
    &:focus + div,
    &:active + div,
    &:hover + div {
      box-shadow: none;
    }
  }
`;

export const iconContainer = {
  base: (theme: Theme) => css`
    ${animations.transition()};
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 1px solid ${theme.primary.borderHover};
  `,
  disabled: (theme: Theme) => css`
    cursor: not-allowed;
    &&& {
      border-color: ${theme.disabled.border};
      background-color: ${theme.disabled.inputBackground};
    }
  `,
  top: css`
    position: relative;
    top: 1px;
  `,
};

export const checkboxContent = {
  base: (theme: Theme) => css`
    ${animations.transition()};
    color: ${theme.text.body};

    &:active {
      color: ${theme.primary.textBase};
    }
  `,
  isFullWidth: css`
    width: 100%;
  `,
  disabled: (theme: Theme) => css`
    cursor: not-allowed;
    color: ${theme.disabled.text};
  `,
};

export const icon = {
  base: (theme: Theme) => css`
    ${animations.transition()};
    color: ${theme.input.backgroundInformation};
    height: 0.75rem;
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  `,
  disabled: (theme: Theme) => css`
    cursor: not-allowed;
    &&& {
      color: ${theme.disabled.inputLabelText};
    }
  `,
};
